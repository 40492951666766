.app-researcher-popup {
	// copy from c-author-pop.scss
	&__author-title {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		grid-column-gap: $article--vertical-spacing-small;
		margin-bottom: $article--vertical-spacing-small;
		margin-right: $article--vertical-spacing-medium;
	}

	&__footer-heading {
		font-family: $context--font-family-sans;
		font-size: map-get($article--font-sizes, 'font-size-3');
		font-weight: 700;
		margin: 0;
	}

	&__author-list {
		list-style: none;
		font-size: map-get($article--font-sizes, 'font-size-3');
		padding: 0;
		margin-top: 0;
		clear: both;
		margin-bottom: $article--vertical-spacing-medium;

		@include media-query('lg') {
			margin-bottom: $article--vertical-spacing-large;
		}

		> li {
			margin-bottom: $article--vertical-spacing-small;
			font-size: $t-a-typography-font-size-xs;

			@include media-query('xs') {
				font-size: $t-a-typography-font-size-s;
			}
		}
	}
	// end of copy from c-author-pop.scss

	&__link {
		@include u-link-inherit();
		color: $t-a-palette-default-greyscale-800;
		display: flex;
		align-items: center;
		gap: spacing(8);
		font-size: $t-a-typography-font-size-xs;

		@include media-query('xs') {
			font-size: $t-a-typography-font-size-s;
		}
	}

	&__label {
		margin: 0;
		font-size: $t-a-typography-font-size-xs;
		font-weight: $t-a-typography-font-weight-bold;
	}

	&__subheading {
		margin: spacing(4) 0 spacing(8) 0;
		font-weight: $t-a-typography-font-weight-bold;
		font-size: 20px;
	}

	&__contacts {
		display: flex;
		flex-wrap: wrap;
		gap: spacing(16) spacing(24);
		padding: spacing(16) 0;
		margin-bottom: spacing(24);
		border-top: $t-a-border-width-25 solid $t-a-palette-default-blue-light;
		border-bottom: $t-a-border-width-25 solid $t-a-palette-default-blue-light;
	}

	&__contact-icon {
		height: $t-a-space-relative-600;
		width: $t-a-space-relative-600;
	}

	&__orcid-icon {
		background-color: #a6ce39;
		fill: $t-a-background-container;
		border-radius: 50%;
	}

	// overrides c-article-authors-search and c-article-identifiers styles set in xslt,
	// needs to stay the same when feature flag is off
	.c-article-authors-search__list {
		display: flex;
		gap: spacing(16);
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;

		@include media-query('xs') {
			justify-content: normal;
		}
	}

	.c-article-authors-search__text {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		font-size: $t-a-typography-font-size-xs;
		flex-direction: column;

		@include media-query('xs') {
			font-size: $t-a-typography-font-size-s;
			flex-direction: row;
		}
	}

	.c-article-authors-search__links-text {
		font-weight: $t-a-typography-font-weight-bold;
		margin-right: spacing(8);
		text-align: center;

		@include media-query('xs') {
			text-align: left;
		}
	}

	.c-article-authors-search__list-item--left {
		// should be the CTA link
		flex: 1 1 auto;
		flex-basis: 100%;

		a {
			width: 100%;
		}

		@include media-query('xs') {
			flex-basis: auto;
		}

		.c-article-authors-search__cta-icon {
			@include u-icon();
			vertical-align: middle;

			@include media-query('xs') {
				@include u-icon(1.5rem, 1.5rem);
			}
		}
	}

	.c-article-authors-search__list-item--right {
		// search for author text
		flex: 1 1 auto;
	}

	.c-article-identifiers {
		margin: 0;
	}

	.c-article-identifiers__item {
		color: $t-a-palette-default-greyscale-800;
		border-right: 2px solid $t-a-palette-default-blue-light;
		font-size: $t-a-typography-font-size-xs;

		@include media-query('xs') {
			font-size: $t-a-typography-font-size-s;
		}

		&:last-child {
			border-right: none;
		}
	}

	.c-article-authors-search__cta-link {
		font-size: $t-a-typography-font-size-xs;

		@include media-query('xs') {
			font-size: $t-a-typography-font-size-s;
		}
	}
}
