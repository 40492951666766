/**
 * Meta
 * Default branding for component
 */

$meta--color: $t-a-palette-default-black;
$meta--font-family: $t-a-typography-font-family-default;
$meta--font-size-large: $t-a-typography-font-size-md;
$meta--font-size: $t-a-typography-font-size-xs;
$meta--font-weight: 700;
$meta--keyline-spacing: $t-a-space-absolute-200;
$meta--line-height: $t-a-typography-line-height-md;
$meta--margin-bottom-large: 20px;
$meta--margin-bottom: 10px;
$meta--text-transform: none;
