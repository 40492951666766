/**
 * Darwin masthead
 * Masthead styles for browsers that pass CTM
 */

.app-article-masthead {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px 0 24px;

	&__info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&__brand {
		display: flex;
		flex-direction: column;
		gap: 8px;
		flex-basis: fit-content;
		flex-shrink: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.8);
		padding: 16px 0 0;
		min-height: 96px;

		img {
			position: absolute;
			left: 0;
			width: 72px;
			height: auto;
			border: 1px solid $t-a-palette-default-white;
 			box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
			border-radius: $masthead--cover-image-border-radius;
		}
	}

	&__syndicated-card {
		border-top: 10px solid $t-a-palette-default-blue-light;
		border-radius: $masthead--cover-image-border-radius;
		background-color: $t-a-palette-default-white;
    	padding: 16px;
	}

	&__syndicated-card-heading {
		color: $t-a-palette-default-black;
		font-size: $t-a-typography-font-size-default;
		font-size: min(max($t-a-typography-font-size-default, 2vw), $t-a-typography-font-size-md);
		margin: 0 0 spacing(16);
	}

	&__syndicated-card-link {
		text-decoration: none;
	}

	&__syndicated-card-from,
	&__syndicated-card-journal {
		display: block;
	}

	&__syndicated-card-main {
		font-size: $t-a-typography-font-size-xs;
		font-weight: $t-a-typography-font-weight-bold;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__journal-link {
		max-width: 400px;
		position: relative;
		display: block;
		padding: 0 0 0 88px;
		margin: 0 0 8px;
		font-size: $t-a-typography-font-size-default;
		font-size: min(max($t-a-typography-font-size-default, 2vw), $t-a-typography-font-size-md);
		font-weight: $t-a-typography-font-weight-bold;
	}

	&__conference-link {
		margin: 0;
		gap:0;
	}

	&__conference-info {
		padding: 8px 0 0 88px;
	}

	&__journal-title {
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	&__submission-link {
		display: flex;
		align-items: center;
		gap: 4px;
		margin: 0 0 0 88px;
		font-size: $t-a-typography-font-size-s;
		font-size: min(max($t-a-typography-font-size-xs, 2vw), $t-a-typography-font-size-s);
	}

	&__access {
		display: flex;
		gap: 4px;
		align-items: center;
		margin: 0;
		font-size: $t-a-typography-font-size-xs;
		font-weight: $t-a-typography-font-weight-light;
		flex-wrap: wrap;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 16px;
	}
}

.app-article-masthead__access svg {
	fill: currentColor;
}

p.app-article-masthead__access--above-download {
	margin: 0 0 spacing(16);
}

.app-article-masthead__brand .app-article-masthead__syndicated-card-image {
	border: none;
	border-radius: 0;
	box-shadow: none;
	position: static;

	&--title {
		max-width: 368px;
		width: auto;
	}

	&--cover {
		display: none;
		max-width: 400px;
		margin: 16px -16px 0;
	}
}

@include from-wide-tablet {
	.app-article-masthead__brand .app-article-masthead__syndicated-card-image--cover {
		display: block;
	}
}

.app-article-masthead a,
.app-article-masthead a:visited {
	color: $t-a-palette-default-white;
}

.app-masthead--pastel .app-article-masthead a,
.app-masthead--pastel .app-article-masthead a:visited {
	color: $t-a-palette-default-black;
}

.app-masthead--pastel .app-article-masthead .c-article-identifiers *,
.app-masthead--pastel .app-article-masthead .c-article-identifiers a:focus,
.app-masthead--pastel .app-article-masthead .c-article-identifiers a:hover {
	color: $t-a-palette-default-black;
}

.app-masthead--pastel .app-article-masthead .c-article-identifiers__item {
	border-left: 1px solid $t-a-palette-default-black;
}

.app-masthead--pastel .c-pdf-download .u-button--primary,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary {
	@include button-primary;
	border: 2px solid transparent;
	background-color: $t-a-link-color-resting;
	box-shadow: none;

	&:hover {
		background: transparent;
		border: 2px solid $t-a-link-color-resting;
		box-shadow: none;
		color: $t-a-link-color-resting;
	}
}

.app-masthead--pastel .c-pdf-download .u-button--secondary,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary {
	@include button-secondary;
	border: 2px solid $t-a-link-color-resting;
	background: transparent;
	color: $t-a-link-color-resting;

	&:hover {
		border: 2px solid transparent;
		background-color: $t-a-link-color-resting;
	}

	&:focus {
		border: 4px solid #fc0;
		color: #01324b;
		background-color: $t-a-palette-default-white;
		background-image: none;
	}
}

.app-article-masthead__syndicated-card a,
.app-article-masthead__syndicated-card a:visited {
	color: $t-a-palette-default-black;
}

@include from-wide-tablet {
	.app-article-masthead {
		flex-direction: row;
		gap: 64px;
		padding: 24px 0;

		&__brand {
			border: 0;
			padding: 0;

			img {
				position: static;
				width: auto;
				height: auto;
			}
		}

		&__buttons {
			margin-top: auto;
			flex-direction: row;
			align-items: center;
		}

		&__journal-link {
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding: 0;
			margin: 0 0 8px;
		}

		&__conference-link {
			margin: 0;
		}

		&__conference-info {
			padding: 8px 0 0;
		}

		&__submission-link {
			margin: 0;
		}
	}

	.app-article-masthead .c-pdf-container {
		flex-grow: 0;
	}
}

@include from-desktop {
	.app-article-masthead__brand {
		flex-basis: 400px;
	}
}

.app-article-masthead__buttons .c-pdf-container {
	justify-content: flex-start;
}

.app-article-masthead .c-article-identifiers {
	line-height: 1;
	margin: 0 0 8px;
	padding: 0;
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-light;
	overflow: hidden;
}

.app-article-masthead .c-article-identifiers--cite-list {
	margin: 0 0 16px;
}

.app-article-masthead .c-article-identifiers *,
.app-article-masthead .c-article-identifiers a:hover,
.app-article-masthead .c-article-identifiers a:focus {
	color: $t-a-palette-default-white;
}

.app-article-masthead .c-cod {
	display:none;
}

.app-article-masthead .c-article-identifiers__item {
	margin: 0 17px 8px -9px;
	padding: 0 0 0 8px;
	border-right: 0;
	border-left: 1px solid $t-a-palette-default-white;
}

.app-article-masthead .c-article-identifiers__item--cite {
	border-left: 0;
}

.app-article-masthead__brand--no-border img {
	border: none;
}

@include media-query('sm', 'max') {
	.app-article-masthead-conference-info {
		padding:0 0 0 88px;
	}
}
