@use "sass:list";
// explicit width and height are defined for images in this stylesheet for web performance reasons to minimise CLS (cumulative layout shift, see: https://web.dev/articles/cls)

$card-open--author-font-size: $t-a-typography-font-size-s;
$card-open--background-color: $t-a-palette-default-white;
$card-open--border-width-style: 1px solid;
$card-open--border: $card-open--border-width-style transparent;
$card-open--font-family: $t-a-typography-font-family-default;
$card-open--gutter: $t-a-space-absolute-400;
$card-open--heading-font-size-desktop: $t-a-typography-font-size-default;
$card-open--heading-font-size-mobile: $t-a-typography-font-size-s;
$card-open--heading-line-height: $t-a-typography-line-height-md;
$card-open--heading-min-height-mobile: 58px;
$card-open--description-font-size: $t-a-typography-font-size-xs;
$card-open--description-font-size-larger: $t-a-typography-font-size-s;
$card-open--description-line-height: $t-a-typography-line-height-md;
$card-open--highlight-border-width-style: 10px solid;
$card-open--image-border: $card-open--border-width-style #cfd8dc;
$card-open--image-width: 192px;
$card-open--compact-image-width: 112px;
$card-open--portrait-image-width: 45px;
$card-open--padding: $t-a-space-absolute-600;
$card-open--title-color: $t-a-palette-default-black;
$card-open--collections-list-gap: $t-a-space-absolute-200;
$card-open--compact-gutter: $t-a-space-absolute-200;
$card-open--compact-padding: $t-a-space-absolute-400;

.app-card-open {
	position: relative;
	border-bottom: $card-open--border;
	background-color: $card-open--background-color;
	padding: $card-open--padding 0;
	border-top: 1px solid $t-a-palette-default-blue-light;

	a:not(.app-card-open__link) {
		// dont apply on title link which may have u-link-faux-block
		position: relative;
	}
}

.app-card-open--last {
	border-bottom: 1px solid $t-a-palette-default-blue-light;
}

.app-card-open__main {
	flex: 1 1 auto;
	word-break: break-word;
	align-self: baseline;
}

.app-card-open__side {
	position: absolute;
	right: 0;
	top: $card-open--padding;
	overflow: hidden;
}

.app-card-open--portrait-ratio .app-card-open__side {
	overflow: auto;
}

.app-card-open img {
	border: $card-open--image-border;
	border-radius: 12px;
	width: $card-open--compact-image-width;
	aspect-ratio: list.slash(16, 9);
}

.app-card-open.app-card-open--portrait-ratio img {
	border-radius: 0;
	width: $card-open--portrait-image-width;
	aspect-ratio: auto;
}

.app-card-open__heading {
	margin-top: 0;
	margin-bottom: $card-open--gutter;
	font-size: $card-open--heading-font-size-mobile;
}

.app-card-open__subheading {
	&::before {
		content: '';
		display: block;
		padding-top: $t-a-space-absolute-200;
	}
}

.app-card-open__link {
	@include u-link-faux-block;
}

.app-card-open__heading a {
	line-height: $card-open--heading-line-height;
}

.app-card-open__description {
	font-size: $card-open--description-font-size;
	line-height: $card-open--description-line-height;
	margin-bottom: $card-open--gutter;

	&--larger {
		@include from-tablet {
			font-size: $card-open--description-font-size-larger;
		}
	}
}

.app-card-open--has-image .app-card-open__heading {
	// subtract the max width of the image, the gutter and the padding-left/right
	max-width: calc(100vw - $card-open--compact-image-width - $card-open--gutter - $t-a-space-absolute-400 - $t-a-space-absolute-400);
	// the heading needs the same height as image to prevent other text content from overflowing into image
	min-height: $card-open--heading-min-height-mobile;
	padding-right: $t-a-space-absolute-200;
}

.app-card-open--has-image.app-card-open--portrait-ratio {
	.app-card-open__heading,
	.app-card-open__description {
		// use portrait-image-width to calculate max-width
		max-width: calc(100vw - $card-open--portrait-image-width - $card-open--gutter - $t-a-space-absolute-400 - $t-a-space-absolute-400);
	}

	.app-card-open__heading {
		// reset min-height as portrait images will likely be beside the text
		min-height: auto;
	}

	.app-card-open__description {
		padding-right: $t-a-space-absolute-200;
	}
}

.app-card-open__collections-list {
	margin-top: $card-open--collections-list-gap;
}

.app-card-open__heading a {
	color: $card-open--title-color;
	font-family: $card-open--font-family;
}

.app-card-open__meta {
	margin-top: $t-a-space-absolute-100;
}

.app-card-open__authors,
.app-card-open__journal {
	display: none;
	font-size: $t-a-typography-font-size-xs;
	line-height: $card-open--heading-line-height;
}

.app-card-open__authors a,
.app-card-open__journal a {
	color: $t-a-palette-default-greyscale-800;
}

.app-card-open--compact.app-card-open {
	padding: $card-open--compact-padding 0;
}

.app-card-open--full-width .app-card-open__main,
.app-card-open--compact .app-card-open__main {
	margin-right: 0;
}

.app-card-open--compact .app-card-open__heading {
	margin-bottom: $card-open--compact-gutter;
	font-size: $card-open--heading-font-size-mobile;
}

.app-card-open__text-container {
	font-size: 0; // needed to collapse any extra whitepsace between HTML elements within
}

.app-card-open__metadata-list {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0;
	flex-direction: column;

	@include from-tablet {
		flex-direction: row;
	}
}

.app-card-open__metadata-list div {
	display: flex;
	border-top: 1px solid $t-a-palette-default-blue-light;
	min-width: 124px;
	padding: $t-a-space-absolute-200 0;
	justify-content: space-between;

	@include from-tablet {
		justify-content: flex-start;
		flex-direction: column;
		padding: 0 $t-a-space-absolute-400;
		border-top: 0;

		&:first-child {
			padding-left: 0;
		}
	}
}

.app-card-open__metadata-list div + div {
	@include from-tablet {
		border-left: 1px solid $t-a-palette-default-blue-light;
	}
}

.app-card-open__metadata-list-term {
	font-size: $t-a-typography-font-size-xs;
}

.app-card-open__metadata-list-description {
	font-weight: $t-a-typography-font-weight-bold;
	font-size: $t-a-typography-font-size-xs;

	@include from-tablet {
		font-size: $t-a-typography-font-size-s;
	}
}

@include from-tablet {
	.app-card-open {
		display: flex;
		justify-content: space-between;
	}

	.app-card-open__main {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		margin-right: $card-open--image-width;
	}

	.app-card-open--has-image .app-card-open__main {
		margin-right: $t-a-space-absolute-400;
	}

	.app-card-open__side {
		position: initial;
		flex: 0 0 auto;
		overflow: hidden;
	}

	.app-card-open img {
		width: $card-open--image-width;
	}

	.app-card-open--compact img {
		width: $card-open--compact-image-width;
	}

	.app-card-open__heading {
		font-size: $card-open--heading-font-size-desktop;
	}

	.app-card-open--has-image .app-card-open__heading {
		max-width: initial;
		min-height: initial;
	}

	.app-card-open.app-card-open--portrait-ratio img {
		width: auto; // <picture> is doing resizing
	}

	.app-card-open__heading,
	.app-card-open__description {
		padding-right: 0;
	}

	.app-card-open__authors,
	.app-card-open__journal {
		display: inline;
	}
}
