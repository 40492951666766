/**
 * Darwin article metrics
 * Article metrics styles for browsers that pass CTM
 */

.app-article-metrics {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

@include from-wide-tablet {
	.app-article-metrics {
		flex-direction: row;
	}
}

.app-article-metrics {
	.app-article-metrics-container {
		flex: 1 0 calc(50% - 24px);
	}
}

.app-article-metrics-container {
	border: 1px solid $t-a-palette-default-blue-light;
	border-radius: 12px;
	display: flex;
	padding: spacing(16);
	font-size: $t-a-typography-font-size-xs;
}

.app-article-metrics-box {
	display: flex;
	flex-direction: column;
	gap: 24px;
	height: 100%;
	width:100%;

	&__main {
		display: flex;
		flex-direction: column;
	    flex: 1 1 75%;

		@include media-query('sm', 'max') {
			border-bottom: 1px solid $t-a-palette-default-blue-light;
			padding-bottom: spacing(16);
		}
	}

	&__side {
		display: flex;
		flex-direction: column;
		flex: 1 0 25%;
		justify-content: space-evenly;

		@include media-query('sm', 'max') {
			flex-direction: row;
		}
	}

	&__side-top {
		justify-content: flex-start;

		@include media-query('sm', 'max') {
			flex-direction: column;
		}
	}

	&__side-column {
		@include media-query('sm', 'max') {
			flex-direction: column;
		}
	}
}

.app-article-metrics-box p {
	margin-bottom: 8px;
}

.app-article-metrics-box__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.8em;
	height: 1.8em;
	background-color: $t-a-palette-default-blue-lighter;
	border-radius: 50%;
}

.app-article-metrics-box-icon {
	display: inline-block;
	width: 24px;
	height: 24px;

	&__metrics {
		fill: none;
	}
}

.app-article-metrics-box--wide {
	&__main {
		flex: 1 1 50%;
	}
}

.app-article-metrics-box--wide__side {
	flex: 1 0 50%;

	@include media-query('sm', 'max') {
		flex-direction: column;
	}
}

.app-article-metrics-stat {
	@include u-list-reset();
	font-family: $meta--font-family;
	font-size: $t-a-typography-font-size-md;
	color: $meta--color;
	display: flex;
	line-height: $t-a-typography-line-height-md;
	gap: 12px;

}

.app-article-metrics-stat__item {
	display: inline-block;
	font-weight: $meta--font-weight;
	text-align: center;
	&:not(:last-child) {
		border-right: 1px solid #999;
		padding-right: spacing(8);
	}
	@include media-query('sm', 'max') {
		display: none;
	}
}

.app-article-metrics-stat__subitem {
	display: block;
	font-size: $meta--font-size;
	font-weight: $t-a-typography-font-weight-normal;
}

.app-article-metrics-count {
	display: inline-block;
	font-weight: $t-a-typography-font-weight-bold;
	text-align: center;
	font-size: $t-a-typography-font-size-xl;
	margin: 0;
}

.app-article-metrics-count_text {
	display: block;
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-normal;
	text-align: center;
}

.c-article-metrics__altmetric-key {
	border-radius: 50%;
	display: block;
	float: left;
	height: 16px;
	margin: 4px 0 0 0;
	width: 16px;

	> span {
		font-weight: $t-a-typography-font-weight-bold;
	}

	$altmetric-colors: (
		twitter: #2f90b9,
		blogs: #ffc200,
		facebook: #071d70,
		googleplus: #912470,
		news: #b60000,
		linkedin: #00bfff,
		reddit: #b9ddeb,
		f1000: #cb2d2d,
		pinterest: #c30,
		qna: #efefef,
		video: #98c973,
		wikipedia: #c7c7c7,
		reviews: #d45bb0,
		citeulike: #bcd2ef,
		mendeley: #a60000,
		connotea: #ff4040
	);

	@each $source, $color in $altmetric-colors {
		&--#{$source} {
			background-color: $color;
		}
	}

	& + span {
		display: block;
		margin-left: calc(16px + 8px);
	}
}

.app-article-metrics-altmetric {
	display: flex;
	gap: 48px;
	align-items: center;

	@include media-query('sm', 'max') {
		display: block;
	}
}

.c-article-metrics__image {
	flex-shrink: 0;

	@include media-query('sm', 'max') {
		text-align: center;
		margin-bottom: 16px;
	}
}

.c-article-metrics__legend {
	@include media-query('md', 'min') {
		flex-basis: 75%;
	}
}

.c-article-metrics__legend ul {
	display: flex;
	flex-wrap: wrap;
    flex-direction: row;
	gap: 8px;

	@include from-wide-tablet {
		flex-direction: column;
	}

	@include from-desktop {
		flex-direction: row;
	}

	@include until-tablet {
		flex-direction: column;
	}
}

.c-article-metrics__legend li {
	flex: 1 0 calc(50% - 16px);
	margin-bottom: 8px;

	@include media-query('sm', 'max') {
		width:100%;
		padding: 4px;
	}
}

.c-article-metrics-heading {
	display: flex;
	gap: 12px;
	align-items: center;
	flex-wrap: wrap;
}

.c-article-metrics__title a {
	color: $t-a-palette-default-greyscale-800;

	&.visited,
	&:visited,
	&.hover,
	&:hover {
		color: $t-a-palette-default-greyscale-800;
	}
}

.c-article-metrics__updated {
	font-size: $t-a-typography-font-size-s;
}

@include from-wide-tablet {
	.app-article-metrics-box {
		flex-direction: row;

		&__main {
			border-right: 1px solid $t-a-palette-default-blue-light;
			padding-right: spacing(24);
		}
	}
}

.app-article-metrics-bar {
	display: flex;
	flex-wrap: wrap;
	font-size: $t-a-typography-font-size-s;
	padding: spacing(16) 0 0;
	row-gap: spacing(24);

	&__item {
		padding: 0 16px 0 0;
	}

	&__count {
		font-weight: $t-a-typography-font-weight-bold;
	}

	&__label {
		font-weight: $t-a-typography-font-weight-normal;
		padding-left: 4px;
	}

	&__icon {
		width: auto;
		height: auto;
		margin-right: 4px;
		margin-top: -4px;
	}

	&__arrow-icon {
		margin: 4px 0 0 4px;
	}

	p {
		margin: 0;
	}
}

.app-article-metrics-bar a,
.app-article-metrics-bar a:visited {
	color: $meta--color;
}

.app-article-metrics-bar .app-article-metrics-bar__item--metrics {
	padding-right: 0;
}

.app-cite-as {
	flex-basis: 100%;
	font-size: $t-a-typography-font-size-s;
	margin-top: 0;
	margin-bottom: spacing(16);
}

.app-cite-as a,
.app-cite-as a:visited {
	color: $meta--color;
}

.c-card-metrics {
	padding: spacing(16) 0;
	border-bottom: 1px solid $t-a-palette-default-blue-light;
}

ul li:last-child > .c-card-metrics {
	border-bottom: none;
}

@include from-tablet {
	.c-card-metrics {
		padding: spacing(24) 0;
	}
}

.c-card-metrics__main {
	word-break: break-word;
}

.c-card-metrics__heading {
	margin-top: 0;
	margin-bottom: spacing(16);
	font-size: $t-a-typography-font-size-default;
	font-size: min(max($t-a-typography-font-size-s, 2vw), $t-a-typography-font-size-default);
}

.c-card-metrics__heading a {
	line-height: $t-a-typography-line-height-md;
	color: $meta--color;
	font-family: $context--font-family-sans;
}

.c-card-metrics__authors {
	display: block;
}
