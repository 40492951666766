.c-book-section,
.c-chapter-body .c-article-section {
	margin-bottom: spacing(32);
}

@include from-desktop {
	.c-book-section,
	.c-chapter-body .c-article-section {
		margin-bottom: spacing(64);
	}
}

.app-overview-section {
	margin: 0 0 spacing(64);
	line-height: $t-a-typography-line-height-md;
	font-size: $t-a-typography-font-size-s;

	&__separator {
		padding: spacing(24) 0 0;
		margin: spacing(24) 0 0;
		border-top: 1px solid $t-a-palette-default-blue-light;
	}

	&__authors dt {
		font-weight: $t-a-typography-font-weight-bold;
		float: left;
		margin-right: spacing(8);
	}

	&__authors {
		line-height: 2;
	}

	&__authors > * {
		margin: 0;
	}
}

.app-overview-section .c-article-author-list {
	line-height: 2;
}

.app-overview-section__book-series + .app-overview-section__conf-series {
	margin-top: spacing(24);
}

.app-book-overview-author-list {
	@include u-list-reset;
}

.app-book-series-listing {
	display: flex;
	gap: 8px;

	&__icon {
	    fill: currentColor;
        width: spacing(24);
        height: spacing(24);
        vertical-align: text-bottom;
	}

	&__description {
		font-weight: $t-a-typography-font-weight-bold;
		margin-bottom: spacing(8);
	}

	&__list {
		@include u-list-reset;
	}

	&__item {
		padding-bottom: spacing(8);
	}

	&--sub-series {
		margin-top: spacing(16);
	}
}

.app-book-series-listing__icon--chapter {
	margin-right: spacing(8);
	vertical-align: text-bottom;
}

.js .app-book-series-listing__list + .app-book__show-button {
	margin: 0;
}

.app-book-conference-proceedings {
	margin-top: spacing(16);
}

.app-book-series-listing__icon--arrow {
	transform: rotate(90deg)
}

.c-book-show-more-less li {
	display: block;
	visibility: visible;
}

.c-book-show-more-less li p {
	margin: 0;
	display: inline;
}

.app-article-metrics-bar {
	margin-top: spacing(8);
}

.c-book-section__title--book {
	margin: 0 0 spacing(24);
}

@include until-desktop {
	.c-book-section__title--book {
		margin: 0 0 spacing(16);
	}
}

.js .app-book__show-button {
	@include link-like;
	font-size: $t-a-typography-font-size-xs;
	background: none;
	border: none;
	padding: 0;
	margin-left: spacing(24);

	svg {
		fill: currentColor;
		vertical-align: text-bottom;
		margin-right: spacing(8);
	}
}

.js .c-list-bullets .app-book__show-button {
	margin-top: spacing(8);
}

@include from-desktop {
	.js .app-book__show-button {
		font-size: $t-a-typography-font-size-s;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.js .app-book__show-button--open svg {
	transform: rotate(180deg);
}

.app-overview-section .app-article-metrics-bar {
	padding-top: 0;
}

/* Buy box show/hide in main body within c-buybox__container depending on viewport */
.c-article-main-column .c-buybox__container {
	display: none;
}

@include until-desktop {
	.c-article-main-column .c-buybox__container {
		display: block;
	}
}

.app-book-sections {
	border: 1px solid $t-a-palette-default-blue-light;
	border-radius: 8px;
	font-size: $t-a-typography-font-size-s;
	padding: spacing(24);

	h2 {
		font-size: $t-a-typography-font-size-default;
	}

	&__list {
		line-height: $t-a-typography-line-height-md;
	}

	&__item:not(:last-child) {
		padding: 0 0 spacing(16);
	}
}

.app-book-search {
	border-radius: 8px;
	background-color: $t-a-palette-default-blue-lighter;
	padding: spacing(24);
	margin-bottom: spacing(32);

	&__label {
		font-size: $t-a-typography-font-size-s;
		font-weight: $t-a-typography-font-weight-bold;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: spacing(24);
	}

	&__input {
		flex: 1;
		font-size: $t-a-typography-font-size-s;
		padding: spacing(8);
		width: 100%;
	}
}

@include from-tablet {
	.app-book-search__content {
		flex-direction: row;
	}
}

.c-pdf-chapter-download__link {
	font-size: $t-a-typography-font-size-s;
	font-weight: $t-a-typography-font-weight-bold;
	vertical-align: top;

	svg {
	    fill: currentColor;
	    margin-left: 4px;
	    margin-top: 2px;
		vertical-align: text-top;
	}
}

@include until-tablet {
	.c-pdf-chapter-download__link--hide-small-screen {
		display: none;
	}
}

.c-book-toc li:first-child .app-card-open {
	padding-top: 0;
}

.c-book-section__back-to-top {
	margin-top: spacing(32);
}

.c-book-toc-pagination + .c-book-section__back-to-top {
	margin-top: 0;
}

.app-card-open__heading img {
	border: none;
	padding-right: 10px;
	aspect-ratio: auto;
}

.app-card-open--has-download-link .app-card-open__heading {
	// subtract the max width of the pdf download, the gutter and the padding-left/right
	max-width: calc(100vw - 70px - spacing(16) - spacing(16) - spacing(16));
}

@include from-tablet {
	.app-card-open--has-download-link .app-card-open__main {
		margin-right: spacing(16);
	}

	.app-card-open--has-download-link .app-card-open__heading  {
		max-width: initial;
	}
}

.c-book-heading--underline {
	border-bottom: 1px solid $t-a-palette-default-blue-light;
	margin-bottom: spacing(24);
	padding-bottom: spacing(8);
}

.c-book-part-heading--underline {
	border-bottom: 2px solid $book--part-heading-border-color;
	margin-bottom: spacing(8);
	padding-bottom: spacing(8);
}

.app-card-open {
    border-bottom: 1px solid $t-a-palette-default-blue-light;
    border-top: 1px solid transparent;
}

.app-article-masthead--book {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: auto;
	padding: $t-a-space-absolute-400 0 $t-a-space-absolute-600;
	/* stylelint-disable declaration-colon-space-after */
	/* stylelint-disable indentation */
	grid-template-areas:
		"breadcrumbs breadcrumbs"
		"journal-cover imprints-and-societies"
		"info info";
	/* stylelint-enable declaration-colon-space-after */
	/* stylelint-enable indentation */

	&__breadcrumbs {
		grid-area: breadcrumbs;
	}

	&__imprints-and-societies {
		grid-area: imprints-and-societies;
		justify-self: flex-end;
	}

	&__title {
		margin: 0;
		line-height: $t-a-typography-line-height-tighter;
		font-weight: $t-a-typography-font-weight-bold;
		word-break: break-word;
	}

	&__title-link {
		&,
		&:hover,
		&:focus,
		&:active,
		&:visited {
			color: currentColor;
		}
	}
}

.app-article-masthead--book__info {
	grid-area: info;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: $t-a-typography-font-size-s;

	&--book-title {
		margin: 0 0 spacing(16) 0;
	}
}

p.app-article-masthead--book__subtitle {
	font-size: $t-a-typography-font-size-md;
	line-height: $t-a-typography-line-height-tighter;
	margin-bottom: spacing(16);
}

.app-article-masthead--book__journal-cover {
	grid-area: journal-cover;

	& img {
		border-radius: $masthead--cover-image-border-radius;
		width: 100%;
		height: auto;
		max-width: 90px;
	}
}

@include from-tablet {
	.app-article-masthead--book {
		grid-template-columns: auto minmax(0, 1fr) auto;
		/* stylelint-disable declaration-colon-space-after */
		/* stylelint-disable indentation */
		grid-template-areas:
		"breadcrumbs breadcrumbs imprints-and-societies"
		"journal-cover info info";
		/* stylelint-enable declaration-colon-space-after */
		/* stylelint-enable indentation */
		padding: $t-a-space-absolute-600 0 $t-a-space-absolute-800;
	}

	.app-article-masthead--book__journal-cover img {
		max-width: 160px;
	}

	.app-article-masthead--book__button--container {
		margin: auto 0 0;
		padding: 0;
	}

	.app-article-masthead--book__info {
		padding-left: $t-a-space-absolute-600;
		line-height: $t-a-typography-line-height-base;
	}

	.app-article-masthead--book__title {
		line-height: $t-a-typography-line-height-tighter;
		font-size: $t-a-typography-heading-level-1-default-font-size;
	}
}

.c-book-other-volumes__list {
	padding-left: spacing(32);
}

.c-book-other-volumes__item {
	padding: 0 0 spacing(16) 4px;
	position: relative;

	&:last-child {
		padding-bottom: 0;
	}

	&--title {
		@include u-h5;
		line-height: $t-a-typography-line-height-base;
		font-weight: $t-a-typography-font-weight-normal;
		margin: 0;
	}
}

.c-book-other-volumes__item--highlighted {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: -32px;
		bottom: 0;
		width: 4px;
		background: $t-a-palette-default-blue-light-400;
		border-radius: 4px;
	}
}

.c-book-other-volumes__item--highlighted .c-book-other-volumes__item--title {
	font-weight: $t-a-typography-font-weight-bold;
}

.c-book-other-volumes__item a,
.c-book-other-volumes__item a:visited,
.c-bibliographic-information__list-item a,
.c-bibliographic-information__list-item a:visited {
	color: $meta--color;
}

/* below styles are a subset of lib/frontend/sites/oscar-sites-springer/src/app-springerlink/scss/partials/darwin/_list-societies.scss */
$listing-link-color: $t-a-palette-default-greyscale-800;
$listing-spacing: spacing(16);

.app-societies__list {
	@include u-list-reset;
	display: grid;
	grid-template-columns: 1fr;
	gap: $listing-spacing 0;

	margin-top: spacing(24);

	@include media-query('sm') {
		grid-template-columns: repeat(2, 1fr);
		gap: $listing-spacing $listing-spacing;
	}
}

.app-societies__list-item {
	display: grid;
	align-items: stretch;
}

.app-societies__list-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $listing-spacing;
	color: $listing-link-color;

	padding: spacing(24);
	border: 1px solid $t-a-palette-default-blue-light;
	border-radius: 8px;
	font-size: $t-a-typography-font-size-xs;
	line-height: $t-a-typography-line-height-md;

	@include media-query('sm') {
		flex-basis: calc(50% - $listing-spacing / 2);
		flex-direction: row;
	}

	&:hover,
	&:visited {
		color: $listing-link-color;
	}
}

.app-societies__list-image {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 125px;

	img {
		max-width: 125px;
		max-height: 72px;
		width: auto;
		height: auto;
	}
}

.app-societies__list-text {
	text-align: center;

	@include media-query('sm') {
		text-align: left;
	}
}

.app-societies__list-external-link-icon {
	width: 16px;
	height: 16px;
	margin-left: spacing(4);
	vertical-align: middle;
	fill: $listing-link-color;
}

.app-article-masthead--book {
	flex-direction: column;
	gap: 24px 16px;
}

.app-article-masthead .c-article-identifiers__copyright {
	vertical-align: text-bottom;
}

.c-article-body .c-article-access-provider__text--chapter {
	color: $t-a-palette-default-greyscale-800;
	font-family: $context--font-family-sans;
	padding: 20px 0;

	svg.c-status-message__icon {
		fill: $book--status-msg-fill-color;
		vertical-align: middle;
	}
}

.c-article-body-section__content--separator {
	padding-top: 40px;
}

.app-article-masthead--book .app-article-masthead__journal-link {
	padding: 0;
}

.app-article-masthead--chapter {
	.app-article-masthead__brand {
		min-height: 115px;
	}
}

@include until-desktop {
	.app-article-masthead--chapter .c-pdf-download .u-sticky-visually-hidden {
		@include u-visually-hidden;
	}

	.app-article-masthead--chapter  .c-pdf-download .c-pdf-download__text {
		text-transform: capitalize;
	}
}

.app-article-masthead--chapter .app-article-masthead__brand {
	flex-shrink: 1;
}

@include from-desktop {
	.app-article-masthead--chapter .app-article-masthead__brand {
		flex-shrink: 0;
	}
}

.c-pdf-download__link {
	max-height: 44px;
}

@include media-query('sm', 'max') {
	.app-article-masthead--book__info .c-pdf-container,
	.app-article-masthead__info .c-pdf-container {
		flex-direction: column;
		gap: 12px;

		& .c-pdf-download + .c-pdf-download {
			margin: 0;
		}
	}
}

.c-book-author-list--expanded .u-js-hide {
	visibility: visible;
	display: inline;
}

.c-book-author-list--expanded .u-js-show-inline {
	display: none;
}

/* Hide the buy box when theres no pricing content. */
.c-book-buy-box:not(:has(.price-info)) {
	display: none;
}

/* but override that if there is a access button. */
.c-book-buy-box:has(.u-button) {
	display: block !important;
}

.app-author-affiliation-list {
	@include u-list-reset;

	&__item {
		font-size: $t-a-typography-font-size-default;
		margin: 0 0 spacing(32);
	}

	&__title {
		font-size: $t-a-typography-font-size-default;
		margin: 0;
		padding-bottom: spacing(8);
	}
}

.c-reading-companion__section-item a:visited {
	color: $t-a-link-color-resting;
}

.c-expand-overlay-wrapper {
	position: relative;
}

.app-article-masthead--book .app-article-masthead__journal-link {
	display: inline-block;
}

@include from-wide-tablet {
	.app-article-masthead--book .app-article-masthead__journal-link {
		display: flex;
	}
}

.c-expand-overlay--book {
	border-radius: $masthead--cover-image-overlay-border-radius;
}

.app-article-policy-section-external-link {
	font-family: $context--font-family-sans;
	line-height: $t-a-typography-line-height-base;
	color: #222;
	font-size: map-get($article--font-sizes, 'font-size-3');

	&:visited {
		color: #222;
	}
}

.app-article-policy-section-external-link-icon {
	vertical-align: middle;
	margin-left: 4px;
}

/* Added until we can update the critical css. */
.app-article-access .u-button--primary,
.app-article-access .u-button--primary:visited {
	color: #fff;
}
