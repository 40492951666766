.c-list-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: $list-header--keyline-gap;
	border-bottom: 2px solid $list-header--keyline-color;
	padding-bottom: spacing(4);
	font-size: $list-header--font-size;
	font-family: $list-header--font-family;
}

.c-list-description {
	@include u-text-interface;
	line-height: $t-a-typography-line-height-base;
	margin-bottom: 0;
	width: 100%; // fix text breaking layout in IE10
}

.c-list-description__item {
	margin-bottom: $list-description--wrapper-margin-bottom;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-list-description__term {
	font-weight: $list-description--font-weight;
	margin-bottom: $list-description--margin-bottom;
}

.c-list-description__details {
	display: block;
}

.c-list-description__details p {
	display: block;
	margin-bottom: $list-description--margin-bottom;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-list-columned {
	column-gap: $list-columned--column-gap;

	@include media-query('sm') {
		column-count: 2;
	}

	@include media-query('md') {
		column-count: 3;
	}
}

.c-list-group {
	@include u-list-reset;
	line-height: $list-group--line-height;
}

@if ($list-group--is-interface ==true) {
	.c-list-group__item > a {
		@include u-link-interface;
	}
}

.c-list-group__item>a.c-list-group__link--bold {
	font-weight: $t-a-typography-font-weight-bold;
}

.c-list-group--xs .c-list-group__item {
	padding: $list-group--padding-xs;
}

.c-list-group--sm .c-list-group__item {
	padding: $list-group--padding-sm;
}

.c-list-group--md .c-list-group__item {
	padding: $list-group--padding-md;
}

.c-list-group--lg .c-list-group__item {
	padding: $list-group--padding-lg;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
	border-bottom: 1px solid $list-group--border-color;
}

.c-list-group--flush .c-list-group__item:first-child {
	padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
	padding-bottom: 0;
}

// TODO: Fix this implementation so that you are able to put the --hide classname on any element, not just <ul> elements
.c-list-bullets ul:not(.c-list-bullets--hide) {
	list-style-type: none;
	list-style-position: initial;
	padding: 0;
	margin-bottom: spacing(48);
}

// Temp fix to remove duplicated margin between elements
.c-list-bullets ul:not(.c-list-bullets--hide):has(+ .u-mt-24) {
	margin-bottom: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li {
	position: relative;
	padding-left: $list-bullets--icon-padding-left;
	line-height: $list-bullets--line-height;
	margin-bottom: 0.5em;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li > ul {
	margin-top: 0.5em;
	margin-bottom: 0;
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide),
.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide) li {
	margin-bottom: 0
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide) li > ul {
	margin-top: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li::before {
	position: absolute;
	content: '';
	left: 0;
	// ensure the vertical positioning of the bullet point marker works for any font-size and taking line-height into consideration
	top: calc((1em * $list-bullets--line-height) / 2 - ($list-bullets--icon-size / 2));
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='%233a9bd6'%3E%3Ccircle cx='50' cy='50' r='50'/%3E%3C/svg%3E");
	width: $list-bullets--icon-size;
	height: $list-bullets--icon-size;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li > ul > li:before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='49.4' cy='49.4' r='44.4' stroke='%233a9bd6' stroke-width='10' fill='none'/%3E%3C/svg%3E");
}

//To solve the problem when an ordered list inside is an unordered list, so that it doesn't use the same style as an "ul" list

.c-list-bullets ul:not(.c-list-bullets--hide) li ol {
	margin: 1em 0 $t-a-space-absolute-400 0;
	padding-inline-start: 1em;

	li {
		padding-left: 0.5em;

		&:before {
			display: none;
		}

	}

	@include from-desktop {
		padding-inline-start: 2em;
	}
}