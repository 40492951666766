.MathJax_SVG svg { // needed to ensure SVGs are sized according to their html width and height attributes
	width: auto;
	height: auto;
}

.MathJax_SVG svg {
	max-width: 100%;
}

#MathJax_Zoom svg {
	max-width: none;
}
