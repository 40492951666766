// Columned list
$list-columned--column-gap: spacing(24);

// Description list
$list-description--wrapper-margin-bottom: spacing(16);
$list-description--font-weight: $t-a-typography-font-weight-bold;
$list-description--font-size: $t-a-typography-font-size-s;
$list-description--line-height: 1.5;
$list-description--margin-bottom: 0;

// Grouped list
$list-group--border-color: #ccc;
$list-group--padding-xs: spacing(4) 0;
$list-group--padding-sm: spacing(8) 0;
$list-group--padding-md: spacing(16) 0;
$list-group--padding-lg: spacing(24) 0;
$list-group--line-height: $t-a-typography-line-height-tight;
$list-group--is-interface: false;

$list-header--keyline-gap: spacing(16);
$list-header--keyline-color: $t-a-palette-default-greyscale-500;
$list-header--font-size: $t-a-typography-font-size-xs;
$list-header--font-family: $t-a-typography-font-family-default;

// List bullets
$list-bullets--line-height: $t-a-typography-line-height-base;
$list-bullets--icon-size: 10px;
$list-bullets--icon-padding-left: spacing(24);
