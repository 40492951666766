.c-breadcrumbs {
	@include u-list-inline;
	font-size: $breadcrumbs-font-size;
	font-size: min(max($breadcrumbs-min-font-size, 2vw), $breadcrumbs-max-font-size);
	font-family: $breadcrumbs-font-family;
	color: $breadcrumbs-color;
}

.c-breadcrumbs__link {
	color: $breadcrumbs-link-color;

	&.visited,
	&:visited,
	&.hover,
	&:hover {
		color: $breadcrumbs-link-color;
	}
}

svg.c-breadcrumbs__chevron {
	margin: $breadcrumbs-chevron-spacing;
	fill: $breadcrumbs-chevron-color;
	width: 10px;
	height: 10px;
}

.c-breadcrumbs--contrast {
	color: $breadcrumbs-contrast-color;

	.c-breadcrumbs__link {
		color: $breadcrumbs-link-contrast-color;

		&.visited,
		&:visited,
		&.hover,
		&:hover {
			color: $breadcrumbs-link-contrast-color;
		}
	}

	svg.c-breadcrumbs__chevron {
		fill: $breadcrumbs-chevron-contrast-color;
	}
}

@include until-tablet {
	// increased selector specificity score needed because of use of u-list-inline mixin in this file
	.c-breadcrumbs .c-breadcrumbs__item {
		display: none;
	}
	.c-breadcrumbs .c-breadcrumbs__item:nth-last-child(1),
	.c-breadcrumbs .c-breadcrumbs__item:nth-last-child(2) {
		display: inline;
	}
}
