$breadcrumbs-font-size: $t-a-typography-font-size-s;
$breadcrumbs-min-font-size: $t-a-typography-font-size-xs;
$breadcrumbs-max-font-size: $t-a-typography-font-size-s;

$breadcrumbs-font-family: $t-a-typography-font-family-default;
$breadcrumbs-color: #333;
$breadcrumbs-link-color: $t-a-link-color-resting;
$breadcrumbs-chevron-color: #333;
$breadcrumbs-chevron-spacing: 0 .25rem;

$breadcrumbs-contrast-color: #fff;
$breadcrumbs-link-contrast-color: #fff;
$breadcrumbs-chevron-contrast-color: #fff;
