/**
 * @springernature/eds/skip-link
 * Default skin settings
 *
 */

$skip-link--padding-y: spacing(8);
$skip-link--font: $t-a-typography-font-family-default;
$skip-link--font-size: $t-a-typography-font-size-s;
$skip-link--color: color('white');
$skip-link--hover-color: color('white');
$skip-link--background-color: color('universal-blue');
$skip-link--z-index: 9999;





