$card-service--color: $t-a-palette-primary-medium-blue;
$card-service--description-color: $t-a-text-primary;
$card-service--font-family: $t-a-typography-font-family-default;
$card-service--font-size: $t-a-typography-font-size-xs;
$card-service--icon-container-padding: 14px; // non-standard value, as requested by Robin design
$card-service--icon-container-size: 64px;
$card-service--icon-size: 32px;
$card-service--line-height: $t-a-typography-line-height-tight;
$card-service--link-icon-size: 16px;
$card-service--link-line-height: $t-a-typography-line-height-md;
$card-service--link-text-font-weight: $t-a-typography-font-weight-bold;

.app-card-service {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: $t-a-space-absolute-400;
	font-size: $card-service--font-size;
	line-height: $card-service--line-height;
	color: $card-service--color;
	font-family: $card-service--font-family;
	padding: $t-a-space-absolute-400 0;
	border-top: 1px solid $t-a-palette-default-blue-light;
}

.app-card-service:last-of-type {
	border-bottom: 1px solid $t-a-palette-default-blue-light;
}

//TODO - Remove this cta color when links scss from EDS is updated with hover color
.app-card-service__link:hover,
.app-card-service__link:focus {
	color: $card-service--color;
}

.app-card-service__link-text {
	font-weight: $card-service--link-text-font-weight;
	line-height: $card-service--link-line-height;
}

.app-card-service__link-icon {
	display: inline-block;
	vertical-align: middle;
	width: $card-service--link-icon-size;
	height: $card-service--link-icon-size;
	fill: $card-service--color;
}

.app-card-service__icon-container {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: $card-service--icon-container-size;
	height: $card-service--icon-container-size;
	border-radius: 50%;
	background-color: $t-a-palette-default-blue-lighter;
	padding: $card-service--icon-container-padding;
}

.app-card-service__icon {
	fill: $t-a-palette-default-blue-light-400;
	width: $card-service--icon-size;
	height: $card-service--icon-size;
}

.app-card-service__description {
	color: $card-service--description-color;
	margin-top: $t-a-space-absolute-200;
}
