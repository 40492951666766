$header--background-color: #fff;
$header--nav-margin-bottom: spacing(4);
$header--item-color: inherit;
$header--logo-height: 24px;
$header--icon-size: 24px;
$header--inner-border-color: #cedbe0;
$header--inner-border: 2px solid $header--inner-border-color;
$header--outer-border-color: #01324b;
$header--outer-border: 2px solid $header--outer-border-color;
$header--expander-background-color: #ebf1f5;
$header--expander-border-color: #02324b;
$header--link-selected: #025e8d;
$header--font-size: 16px;
$header--link-padding: spacing(16) spacing(8);
$header--selected-lozenge-height: 6px;
$header--divider-height: calc(100% - 16px);
$header--divider-top: 8px;
$header--divider-margin-left: -#{spacing(16) - 1px}; // -1px to move it out of screen when Search button moves to next line
$header--heading-padding: spacing(16) spacing(16) 0;
$header--search-input-padding: spacing(8) spacing(48) spacing(8) spacing(8);

.c-header {
	@include u-text-interface;
	line-height: 1.4; // can't use $t-a-typography-line-height-tight because this component needs to work for both frontend-toolkits and EDS
	background-color: $header--background-color;
	padding: spacing(8) 0 0;
	border-bottom: $header--outer-border;
	font-size: $header--font-size;
}

.c-header__container {
	@include u-container;
	margin: 0 auto spacing(8);
	padding: 0 spacing(8);
	justify-content: space-between;
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: spacing(8) spacing(16);
}

.c-header__nav {
	border-top: $header--inner-border;
	padding-top: spacing(4);
	position: relative;
}

.c-header__nav-container {
	@include u-container;
	padding: 0 spacing(8);
	margin-bottom: $header--nav-margin-bottom;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: relative;

	> * {
		&:not(:last-child) {
			margin-right: spacing(32);
		}
	}
}

.c-header__link-container {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	gap: spacing(8) spacing(16);
	justify-content: space-between;
}

.c-header__list {
	@include u-list-reset;
}

.c-header__list-item {
	@include u-container;
	font-weight: bold;
	padding: spacing(8);

	&:not(:last-child) {
		border-bottom: $header--inner-border;
	}
}

.c-header__item {
	color: $header--item-color;
}

.c-header__item--menu {
	@include media-query('sm') {
		@include u-hide;
	}
}

.c-header__item--inline-links {
	@include u-hide;

	@include media-query('sm') {
		display: flex;
		gap: spacing(16);
		visibility: visible;
	}
}

.c-header__item--divider {
	&:before {
		content: '';
		position: absolute;
		border-left: $header--inner-border;
		height: $header--divider-height;
		top: $header--divider-top;
		margin-left: $header--divider-margin-left;
	}
}

.c-header__brand {
	a {
		display: block;
		line-height: 1;
		padding: $header--link-padding;
		text-decoration: none;
	}

	img {
		height: $header--logo-height;
		width: auto;
	}
}

.c-header__link {
	display: inline-block;
	padding: $header--link-padding;
	color: $header--item-color;
	font-weight: bold;
	text-decoration-color: transparent;
	position: relative;
	word-break: normal;
	white-space: nowrap;

	&:visited {
		color: $header--item-color;
	}

	&:hover {
		color: $header--item-color;
		text-decoration-color: $header--item-color;
	}
}

.c-header__link--static {
	flex: 0 0 auto;
}

.c-header__link.is-open {
	color: $header--link-selected;

	&::after {
		content: '';
		background-color: $header--link-selected;
		width: 100%;
		height: $header--selected-lozenge-height;
		border-radius: 4px;
		position: absolute;
		bottom: -4px;
		left: 0;
	}
}

.c-header__icon {
	@include u-icon;
	vertical-align: bottom;
	font-size: $header--icon-size;

	 + * {
		 margin-left: spacing(8);
	 }
}

.c-header__expander {
	background-color: $header--expander-background-color;
}

.c-header__search {
	padding: spacing(24) 0;

	@include media-query('sm') {
		max-width: 70%;
	}
}

.c-header__search-container {
	position: relative;
}

.c-header__search-label {
	display: inline-block;
	color: $header--item-color;
	font-weight: bold;
	margin-bottom: spacing(8);
}

.c-header__search-input {
	border: 1px solid black;
	width: 100%;
	padding: $header--search-input-padding;
	background-color: white;
}

.c-header__search-button {
	background-color: transparent;
	border: none;
	color: $header--item-color;
	position: absolute;
	height: 100%;
	padding: 0 spacing(8);
	right: 0;
}

.has-tethered.c-header__expander {
	margin-top: -#{spacing(2)};
	width: 100%;
	top: 100%;
	z-index: 10; // increased to 10 to be greater than journal cover image
	left: 0;
	border-bottom: $header--outer-border;
}

.has-tethered.c-header__expander--menu {
	@include media-query('sm') {
		@include u-hide;
	}
}

.has-tethered.c-header__expander--account {
	transform: translateY(#{spacing(8)});
	width: auto;
	left: auto;
	position: absolute;
	right: 0;
	bottom: auto;
	margin-top: 0;
}

.has-tethered {
	.c-header__heading {
		@include u-hide;
	}

	.c-header__search {
		margin: auto;
	}
}

.c-header__heading {
	@include u-container;
	padding: $header--heading-padding;
}
