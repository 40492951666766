$masthead-color: #fff;
$masthead-default-background-color: #0070a8;
$masthead-pastel-default-background-color: rgb(102, 170, 204);

.app-masthead__colour-1 {
	--background-color: rgb(255, 21, 0);
}

.app-masthead__colour-2 {
	--background-color: rgb(255, 64, 0);
}

.app-masthead__colour-3 {
	--background-color: rgb(255, 106, 0);
}

.app-masthead__colour-4 {
	--background-color: rgb(255, 149, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-5 {
	--background-color: rgb(255, 191, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-6 {
	--background-color: rgb(255, 234, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-7 {
	--background-color: rgb(234, 255, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-8 {
	--background-color: rgb(191, 255, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-9 {
	--background-color: rgb(149, 255, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-10 {
	--background-color: rgb(106, 255, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-11 {
	--background-color: rgb(43, 255, 0);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-12 {
	--background-color: rgb(0, 255, 43);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-13 {
	--background-color: rgb(0, 255, 106);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-14 {
	--background-color: rgb(0, 255, 149);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-15 {
	--background-color: rgb(0, 255, 191);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-16 {
	--background-color: rgb(0, 255, 234);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-17 {
	--background-color: rgb(0, 234, 255);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-18 {
	--background-color: rgb(0, 191, 255);
	--gradient-light: rgba(0, 0, 0, 0.5);
	--gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-19 {
	--background-color: rgb(0, 149, 255);
}

.app-masthead__colour-20 {
	--background-color: rgb(0, 106, 255);
}

.app-masthead__colour-21 {
	--background-color: rgb(0, 21, 255);
}

.app-masthead__colour-22 {
	--background-color: rgb(121, 77, 255);
}

.app-masthead__colour-23 {
	--background-color: rgb(151, 77, 255);
}

.app-masthead__colour-24 {
	--background-color: rgb(170, 51, 255);
}

.app-masthead__colour-25 {
	--background-color: rgb(198, 26, 255);
}

.app-masthead__colour-26 {
	--background-color: rgb(255, 0, 234);
}

.app-masthead__colour-27 {
	--background-color: rgb(255, 0, 191);
}

.app-masthead__colour-28 {
	--background-color: rgb(255, 0, 149);
}

.app-masthead__colour-29 {
	--background-color: rgb(255, 0, 106);
}

.app-masthead__colour-30 {
	--background-color: rgb(255, 0, 64);
}

.app-masthead__colour-31 {
	--background-color: rgb(255, 0, 21);
}

.app-masthead--pastel {
	--gradient-light: rgba(255, 255, 255, 0.9);
	--gradient-dark: rgba(255, 255, 255, 0.75);
	--masthead-color: #000;
}

.app-masthead__colour-1--pastel {
	--background-color: rgb(255, 138, 128);
}

.app-masthead__colour-2--pastel {
	--background-color: rgb(255, 159, 128);
}

.app-masthead__colour-3--pastel {
	--background-color: rgb(255, 181, 128);
}

.app-masthead__colour-4--pastel {
	--background-color: rgb(255, 191, 102);
}

.app-masthead__colour-5--pastel {
	--background-color: rgb(255, 217, 102);
}

.app-masthead__colour-6--pastel {
	--background-color: rgb(255, 242, 102);
}

.app-masthead__colour-7--pastel {
	--background-color: rgb(242, 255, 102);
}

.app-masthead__colour-8--pastel {
	--background-color: rgb(223, 255, 128);
}

.app-masthead__colour-9--pastel {
	--background-color: rgb(202, 255, 128);
}

.app-masthead__colour-10--pastel {
	--background-color: rgb(181, 255, 128);
}

.app-masthead__colour-11--pastel {
	--background-color: rgb(170, 255, 153);
}

.app-masthead__colour-12--pastel {
	--background-color: rgb(153, 255, 170);
}

.app-masthead__colour-13--pastel {
	--background-color: rgb(153, 255, 195);
}

.app-masthead__colour-14--pastel {
	--background-color: rgb(153, 255, 204);
}

.app-masthead__colour-15--pastel {
	--background-color: rgb(153, 255, 229);
}

.app-masthead__colour-16--pastel {
	--background-color: rgb(153, 255, 246);
}

.app-masthead__colour-17--pastel {
	--background-color: rgb(153, 246, 255);
}

.app-masthead__colour-18--pastel {
	--background-color: rgb(153, 229, 255);
}

.app-masthead__colour-19--pastel {
	--background-color: rgb(153, 212, 255);
}

.app-masthead__colour-20--pastel {
	--background-color: rgb(153, 195, 255);
}

.app-masthead__colour-21--pastel {
	--background-color: rgb(178, 191, 255);
}

.app-masthead__colour-22--pastel {
	--background-color: rgb(178, 178, 255);
}

.app-masthead__colour-23--pastel {
	--background-color: rgb(198, 178, 255);
}

.app-masthead__colour-24--pastel {
	--background-color: rgb(210, 178, 255);
}

.app-masthead__colour-25--pastel {
	--background-color: rgb(223, 178, 255);
}

.app-masthead__colour-26--pastel {
	--background-color: rgb(236, 178, 255);
}

.app-masthead__colour-27--pastel {
	--background-color: rgb(255, 178, 249);
}

.app-masthead__colour-28--pastel {
	--background-color: rgb(255, 153, 229);
}

.app-masthead__colour-29--pastel {
	--background-color: rgb(255, 153, 212);
}

.app-masthead__colour-30--pastel {
	--background-color: rgb(255, 153, 195);
}

.app-masthead__colour-31--pastel {
	--background-color: rgb(255, 153, 161);
}

.app-masthead {
	position: relative;
	background: $masthead-default-background-color;
	background: var(--background-color, $masthead-default-background-color);
}
.app-masthead::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: radial-gradient(circle at top right, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
}

.app-masthead--pastel .app-masthead {
	background: $masthead-pastel-default-background-color;
	background: var(--background-color, $masthead-pastel-default-background-color);
}

.app-masthead.app-masthead--disable-journal-colour::after {
	background: unset;
}

@include until-tablet {
	.app-masthead::after {
		background: linear-gradient(225deg, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
	}
}

.app-masthead__container {
	@include u-container;

	position: relative;
	z-index: 1;
	color: var(--masthead-color, $masthead-color);
}
