.c-meta {
	@include u-list-reset();
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-family: $meta--font-family;
	font-size: $meta--font-size;
	color: $meta--color;
	line-height: $meta--line-height;
}

.c-meta--large {
	font-size: $meta--font-size-large;

	.c-meta__item {
		margin-bottom: $meta--margin-bottom-large;
	}
}

.c-meta__item {
	&:not(:last-child) {
		@include u-keyline($direction:'right', $spacing: $meta--keyline-spacing, $thickness: 2px);
		line-height: 1;
		border-color: $t-l-color-blue-300;
	}
}

.c-meta__item--spacing {
	&:not(:last-child) {
		margin-bottom: spacing(4);
	}
}

.c-meta__item--block-sm-max {
	@include media-query('sm', 'max') {
		display: block;

		&:not(:last-child) {
			border-right: none;
			margin-right: 0;
			padding-right: 0;
		}
	}
}

.c-meta__item--block-at-lg {
	@include media-query('lg') {
		display: block;

		&:not(:last-child) {
			border-right: none;
			margin-right: 0;
			padding-right: 0;
		}
	}
}

.c-meta__type {
	text-transform: $meta--text-transform;
	font-weight: $meta--font-weight;
}

.c-meta__link {
	color: color('black');
	text-decoration: underline;
}

.c-meta__link:visited {
	color: color('black');
}
